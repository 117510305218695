import React, { forwardRef, type ReactNode } from 'react'
import cx from 'classnames'


export type WidthContainerProps = {
  children?: ReactNode
  id?: string
  className?: string
  ignore?: boolean
  size?: 1440 | 1312 | 1136 | 944
  role?: React.HTMLAttributes<HTMLDivElement>['role']
  style?: React.HTMLAttributes<HTMLDivElement>['style']
  'data-testid'?: string
  dataAttributes?: Record<string, any>
}

const WidthContainer = forwardRef<HTMLDivElement, WidthContainerProps>((props, ref) => {
  const { children, id, className, ignore, size = 1136, role, style, 'data-testid': dataTestId, dataAttributes } = props

  const rootClassName = cx(className, {
    'px-16 mx-auto': !ignore,
    'max-w-1440': !ignore && size === 1440,
    'max-w-1312': !ignore && size === 1312,
    'max-w-1168': !ignore && size === 1136,
    'max-w-976': !ignore && size === 944,
  })

  return (
    <div ref={ref} id={id} className={rootClassName} style={style} role={role} data-testid={dataTestId} {...dataAttributes}>
      {children}
    </div>
  )
})

WidthContainer.displayName = 'WidthContainer'


export default WidthContainer
